.central-form-container {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    -webkit-transform: translate(-50%, -50%);
    flex-grow: 1;
}

@media (max-width: 830px) {
    .central-form-container {
        width: 100%;
        left: 0;
        top: 0;
        -webkit-transform: none;
    }
}

.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1000; /* Ensure it's above other content */
}

.hidden {
    display: none;
}
