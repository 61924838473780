html, body {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.content {
    flex-grow: 1;
}

.footer {
    flex-shrink: 0;
}
