@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&display=swap');
.central-form-container {
  background: white;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  position: fixed; /* fixed to ignore surrounding content */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  position: fixed;
  top: 80px; /* Header's height */
  left: 0;
  width: 100%;
  height: calc(100% - 80px); /* Exclude Header's height */
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
