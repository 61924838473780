/* LoginPage.css */

.login-form-header {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}

.form-group {
    margin-bottom: 15px;
}

.form-control {
    width: 90%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.login-button {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0056b3;
}

.popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 0, 0.9); /* Yellow background */
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Above other elements */
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

.popup.show {
    opacity: 1;
    visibility: visible;
}
